import Link from 'next/link';
import { type FunctionComponent } from 'react';
import { HiCheck } from 'react-icons/hi';

import { Headline } from '../headline';
import { ShinyContainer } from '../shinycontainer';

interface ServicesProps {}

const pricing = {
  tiers: [
    {
      id: 'backend-brilliance',
      href: '/services/backend-brilliance',
      name: 'Backend Brilliance',
      description:
        'Harness the power of high-performance and secure backend solutions. I specialize in creating scalable and robust backend architecture that serves as a solid foundation for your applications.',
      features: [
        'API Development',
        'Database Management',
        'Server Management',
        'Performance Tuning',
        'Data Integration',
      ],
    },
    {
      id: 'software-structure-solutions',
      href: '/services/software-structure-solutions',
      name: 'Software Structure Solutions',
      description:
        'Optimize your software processes with my comprehensive architecture services. I design effective and efficient software architecture that caters to your specific business needs and promotes growth.',
      features: [
        'System Design',
        'Integration Architecture',
        'Microservices',
        'Security Architecture',
        'Architecture Review & Advisory',
      ],
    },
    {
      id: 'frontend-finesse',
      href: '/services/frontend-finesse',
      name: 'Frontend Finesse',
      description:
        'Experience visually stunning and interactive frontend interfaces that offer excellent user experiences. I craft appealing and intuitive frontend design that aligns with your business objectives.',
      features: [
        'Responsive Web Design',
        'User Interface (UI) Design',
        'User Experience (UX) Design',
        'Performance Optimization',
        'Cross-Browser Compatibility',
      ],
    },
  ],
} as const;

const Services: FunctionComponent<ServicesProps> = () => {
  return (
    <section className="mx-auto max-w-7xl px-6 lg:px-8">
      <a id="services" className="relative -top-16" />
      <div className="mx-auto max-w-4xl">
        <Headline as="h2" className="flex w-full justify-center md:flex-none">
          Powering your progress
        </Headline>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
          Unleashing potential with my tailored Freelance Services
        </p>
      </div>
      <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        {pricing.tiers.map((tier) => (
          <ShinyContainer
            key={tier.id}
            className="relative overflow-hidden rounded-3xl p-8 ring-1 ring-white/10 transition focus-within:ring-2 focus-within:ring-accent-500 focus-within:ring-offset-2 focus-within:ring-offset-primary-600/50 hover:border-accent-500 hover:ring-accent-500 hover:ring-offset-1 hover:ring-offset-primary-600/50 xl:p-10"
          >
            <Link href={tier.href} className="focus:outline-none">
              <div className="flex items-center justify-between gap-x-4">
                <h2
                  id={tier.id}
                  className="text-lg font-semibold leading-8 text-secondary-500"
                >
                  {tier.name}
                </h2>
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-300">
                {tier.description}
              </p>

              <ul
                role="list"
                className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10"
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <HiCheck
                      className="h-6 w-5 flex-none text-accent-500"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </Link>
          </ShinyContainer>
        ))}
      </div>
    </section>
  );
};

export { Services };
