const aspectRatio = require('@tailwindcss/aspect-ratio');
const forms = require('@tailwindcss/forms');

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    fontFamily: {
      bebas: ['Bebas Neue', 'sans-serif'],
      aller: ['Aller', 'sans-serif'],
    },
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
      '7xl': '5rem',
      '8xl': '6rem',
      'headline-xs': '5rem',
      'headline-sm': '8rem',
      'headline-md': '8rem',
      'headline-lg': '10rem',
      'headline-xl': '12rem',
    },
    extend: {
      height: {
        'hero-filter-1': '30rem',
      },
      width: {
        'hero-filter-2': '50%',
      },
      maxHeight: {
        hero: '55rem',
      },
      minHeight: {
        hero: '20rem',
      },
      textColor: {
        primary: {
          100: '#ccd0d2',
          200: '#99a1a5',
          300: '#667378',
          400: '#33444b',
          500: '#00151e',
          600: '#001118',
          700: '#000d12',
          800: '#00080c',
          900: '#000406',
        },
        secondary: {
          100: '#fdfffe',
          200: '#fbfffc',
          300: '#fafffb',
          400: '#f8fff9',
          500: '#f6fff8',
          600: '#c5ccc6',
          700: '#949995',
          800: '#626663',
          900: '#313332',
        },
        accent: {
          100: '#eacede',
          200: '#d49dbd',
          300: '#bf6c9d',
          400: '#a93b7c',
          500: '#940a5b',
          600: '#760849',
          700: '#590637',
          800: '#3b0424',
          900: '#1e0212',
        },
      },
      colors: {
        primary: {
          100: '#ccd0d2',
          200: '#99a1a5',
          300: '#667378',
          400: '#33444b',
          500: '#00151e',
          600: '#001118',
          700: '#000d12',
          800: '#00080c',
          900: '#000406',
        },
        secondary: {
          100: '#fdfffe',
          200: '#fbfffc',
          300: '#fafffb',
          400: '#f8fff9',
          500: '#f6fff8',
          600: '#c5ccc6',
          700: '#949995',
          800: '#626663',
          900: '#313332',
        },
        accent: {
          100: '#eacede',
          200: '#d49dbd',
          300: '#bf6c9d',
          400: '#a93b7c',
          500: '#940a5b',
          600: '#760849',
          700: '#590637',
          800: '#3b0424',
          900: '#1e0212',
        },
      },
      backgroundColor: {
        primary: {
          100: '#ccd0d2',
          200: '#99a1a5',
          300: '#667378',
          400: '#33444b',
          500: '#00151e',
          600: '#001118',
          700: '#000d12',
          800: '#00080c',
          900: '#000406',
        },
        secondary: {
          100: '#fdfffe',
          200: '#fbfffc',
          300: '#fafffb',
          400: '#f8fff9',
          500: '#f6fff8',
          600: '#c5ccc6',
          700: '#949995',
          800: '#626663',
          900: '#313332',
        },
        accent: {
          100: '#eacede',
          200: '#d49dbd',
          300: '#bf6c9d',
          400: '#a93b7c',
          500: '#940a5b',
          600: '#760849',
          700: '#590637',
          800: '#3b0424',
          900: '#1e0212',
        },
      },
      lineHeight: {
        headline: '0.85em',
      },
    },
  },
  corePlugins: {
    aspectRatio: false,
  },
  plugins: [aspectRatio, forms],
};
