export type JobEntry = {
  title: string;
  description: string;
  date: string;
  tags?: string[];
};

export type TimeEntry = {
  title: string;
  description: string;
  date: string;
};

export type TimeLineEntry = JobEntry | TimeEntry;

export type Timeentries = TimeLineEntry[];

export const timeentries: Timeentries = [
  {
    title: 'Self Employment started',
    description: 'NIPE Solutions e.U. was founded',
    date: '2022-08-22',
  },
  {
    title: 'Sr. Software Engineer',
    description: 'Sr. Software Engineer at Amundi',
    date: '2021-05-01 - 2022-09-31',
  },
  {
    title: 'Software Engineer',
    description: 'Software Engineer at Accenture',
    date: '2019-05-01 - 2021-04-30',
  },
  {
    title: 'Skilled Worker',
    description:
      'Skilled Worker at Siemens AG Austria, Programming SAP ABAP and Java',
    date: '2018-03-01 - 2019-04-30',
  },
  {
    title: 'Apprenticeship as IT Specialist',
    description: 'Apprenticeship as IT Specialist at Siemens AG Austria',
    date: '2014-09-01 - 2018-02-28',
  },
  {
    title: 'Started to program in Java',
    description: 'Layed the foundation for my career',
    date: '2011-03',
  },
];
