import type { FunctionComponent } from 'react';
import { Balancer } from 'react-wrap-balancer';

interface AfterContactProps {}

export const AfterContact: FunctionComponent<AfterContactProps> = () => {
  return (
    <div className="max-w-md flex-1 p-8">
      <p className="mb-6 text-2xl">
        <span className="font-bold">
          Boom! 🚀 Your Message is in the House!
        </span>
      </p>

      <div className="flex flex-col gap-3 text-lg">
        <p>
          <Balancer>
            Big thanks for hitting me up! Your digital vibes are vibing in my
            inbox, and I&apos;m all over it. While you&apos;re chilling, picture
            your words doing a cool dance through the cyber universe, leaving
            some sparkles! <br /> <br /> I&apos;ll slide into your inbox
            shortly—stay tuned! Keep rocking the code, stay rad, and remember,
            in a world of 0s and 1s, be the #HEX! <br /> <br /> Catch you on the
            flip side ✌️,
            <br /> Nicholas Petrasek
          </Balancer>
        </p>
      </div>
    </div>
  );
};
