import type { FunctionComponent, HTMLAttributes } from 'react';
import { useEffect, useMemo, useState } from 'react';
import Balancer from 'react-wrap-balancer';

import { mergeClasses } from '@/utils/utils';

const transformHeadline = (name: string) => {
  if (!name || name.length === 0) {
    return '';
  }
  return (
    name.charAt(0).toUpperCase() +
    name.slice(1).toLowerCase().replaceAll(' ', '_')
  );
};

const defaultStyles =
  'w-full font-aller font-bold tracking-tighter text-secondary-500 text-3xl sm:text-4xl';
const defaultPrefixStyle = 'text-accent-500';

interface HeadlineProps extends HTMLAttributes<HTMLHeadingElement> {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  prefix?: string;
  prefixClassName?: string;
}

const Headline: FunctionComponent<HeadlineProps> = ({
  className,
  children,
  prefixClassName,
  as: Component = 'h1',
  prefix = '#',
  ...props
}) => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const transformedHeadline = useMemo(() => {
    if (typeof children === 'string') {
      return transformHeadline(children);
    }
    return children;
  }, [children]);

  return (
    <Component {...props} className={mergeClasses(defaultStyles, className)}>
      {isMounted && (
        <Balancer>
          <span className={mergeClasses(defaultPrefixStyle, prefixClassName)}>
            {prefix}
          </span>
          {transformedHeadline}
        </Balancer>
      )}
    </Component>
  );
};

export { Headline };
