import { create } from 'zustand';

export type ContactFormStateType = 'idle' | 'submitting' | 'success' | 'error';

export type ContactStoreType = {
  state: ContactFormStateType;
  setState: (state: ContactFormStateType) => void;
  reset: () => void;
  wasSubmitted: () => boolean;
};

export const useContactStore = create<ContactStoreType>((set, get) => ({
  state: 'idle',
  setState: (state: ContactFormStateType) => set({ state }),
  reset: () => set({ state: 'idle' }),
  wasSubmitted: () => get().state === 'success',
}));
