import { motion, useAnimation } from 'framer-motion';
import { type FunctionComponent, useEffect } from 'react';

interface ShinyContainerProps {
  className?: string;
  children: React.ReactNode;
}

const hoverAnimation = {
  rest: {
    x: '-100%',
    transition: {
      duration: 0,
    },
  },
  hover: {
    x: '100%',
    transition: {
      duration: 0.3,
    },
  },
} as const;

const ShinyContainer: FunctionComponent<ShinyContainerProps> = ({
  children,
  className,
}) => {
  const controls = useAnimation();

  const startAnimation = () => controls.start('hover');
  const stopAnimation = () => controls.start('rest');

  useEffect(() => {
    return () => controls.stop(); // Stop the animation when the component unmounts
  }, [controls]);

  return (
    <motion.div
      className={`relative inline-block cursor-pointer overflow-hidden ${className}`}
      onHoverStart={startAnimation}
      onHoverEnd={stopAnimation}
    >
      {children}
      <motion.div
        className="absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent via-secondary-500 to-transparent opacity-20"
        animate={controls}
        variants={hoverAnimation}
        initial="rest"
      />
    </motion.div>
  );
};

export { ShinyContainer };
