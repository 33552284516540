import classNames from 'classnames';
import type { FormikContextType, FormikHelpers, FormikProps } from 'formik';
import { connect, Field, Form, Formik, getIn } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import type { FC } from 'react';
import React from 'react';
import { HiArrowRight } from 'react-icons/hi';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useContactStore } from './ContactStore';

interface ContactFormProps {}

interface FormState {
  name?: string;
  email?: string;
  message?: string;
}

const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
};

const initialValues: FormState = {
  name: '',
  email: '',
  message: '',
};

const ContactShema = Yup.object().shape({
  name: Yup.string().required('Please enter your name'),
  email: Yup.string()
    .email('Your email is invalid')
    .required("I can't reply to you if you don't give me your email"),
  message: Yup.string()
    .min(10, 'Message is too short (minimum is 10 characters)')
    .max(1000, 'Message is too long (maximum is 1000 characters)')
    .required('What do you want to tell me?'),
});

const getErrorClasses = <T, K extends keyof T>(
  formik: FormikProps<T>,
  name: K
) => {
  const { errors, touched } = formik;
  const errorClassnames = classNames(
    'rounded-md bg-secondary-500 p-2 text-black focus:outline-none focus:ring-2 focus:ring-accent-500 border-2 focus:border-transparent',
    { 'border-2 border-red-500': errors[name] && touched[name] }
  );

  return errorClassnames;
};

/*
data-netlify-honeypot="bot-field"
              data-netlify-recaptcha="true"

*/

const errorTextClassnames = classNames('text-red-500/80 text-sm font-semibold');

interface CustomErrorMessageProps {
  name: string;
}

const CustomErrorMessageImpl: FC<
  CustomErrorMessageProps & { formik: FormikContextType<any> }
> = ({ name, formik }) => {
  const touch = getIn(formik.touched, name);
  const error = getIn(formik.errors, name);
  return (
    <div className="h-5">
      <AnimatePresence>
        {!!touch && !!error ? (
          <motion.span
            key={`${name}-error`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={errorTextClassnames}
          >
            {error}
          </motion.span>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

const CustomErrorMessage = connect<
  CustomErrorMessageProps,
  CustomErrorMessageProps & { formik: FormikContextType<any> }
>(CustomErrorMessageImpl);

export const ContactForm: FC<ContactFormProps> = () => {
  const setState = useContactStore((store) => store.setState);

  const handleSubmit = async (
    values: FormState,
    { setSubmitting }: FormikHelpers<FormState>
  ) => {
    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact-form', ...values }),
      });
      setSubmitting(false);
      setState('success');
      toast('Message sent successfully! 🎉');
    } catch {
      setSubmitting(false);
      setState('error');
      toast.error('Something went wrong! 😢');
    }
  };

  return (
    <div className="flex-1 p-8">
      <Formik
        initialValues={initialValues}
        validationSchema={ContactShema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          const { isValid, dirty, isSubmitting } = formik;
          return (
            <Form
              id="contact-form"
              name="contact-form"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              className="space-y-4"
            >
              <p className="hidden">
                <label>
                  Don&apos;t fill this out if you&apos;re human:{' '}
                  <input name="bot-field" />
                </label>
              </p>
              <div className="flex flex-col">
                <label htmlFor="name" className="mb-2 text-lg">
                  Your Name
                </label>

                <Field
                  id="name"
                  name="name"
                  type="text"
                  className={getErrorClasses(formik, 'name')}
                  placeholder="James Bond"
                  required
                  autoComplete="name"
                />

                <CustomErrorMessage name="name" />
              </div>
              <div className="flex flex-col">
                <label htmlFor="email" className="mb-2 text-lg">
                  Email
                </label>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  className={getErrorClasses(formik, 'email')}
                  placeholder="james@bond.com"
                  required
                  autoComplete="email"
                />

                <CustomErrorMessage name="email" />
              </div>
              <div className="flex flex-col">
                <label htmlFor="message" className="mb-2 text-lg">
                  Message
                </label>
                <Field
                  type="textarea"
                  as="textarea"
                  id="message"
                  name="message"
                  rows="5"
                  className={getErrorClasses(formik, 'message')}
                  placeholder="Type your message here..."
                  required
                />

                <CustomErrorMessage name="message" />
              </div>
              {/* <div data-netlify-recaptcha="true" className="form-row"></div> */}
              <button
                type="submit"
                disabled={!(dirty && isValid) || isSubmitting}
                className="flex w-full items-center justify-center gap-2 rounded-md bg-accent-500 p-3 font-bold text-white duration-300 hover:bg-accent-400 focus:outline-none focus:ring-2 focus:ring-accent-500 focus:ring-offset-2 focus:ring-offset-secondary-500 disabled:cursor-not-allowed disabled:opacity-50"
              >
                Send Message
                <HiArrowRight />
              </button>
            </Form>
          );
        }}
      </Formik>
      <div className="mt-6 text-center">
        <p className="text-lg italic">
          <span className="text-accent-500">&quot;</span>Coding the future , one
          line at a time!<span className="text-accent-500">&quot;</span>
        </p>
      </div>
    </div>
  );
};
