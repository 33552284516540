import Image from 'next/image';
import Link from 'next/link';
import { useInView } from 'react-intersection-observer';
import { Balancer } from 'react-wrap-balancer';

import { useInViewStore } from './store';

const HeroBanner = () => {
  const setInView = useInViewStore((state) => state.setInView);
  const { ref } = useInView({
    threshold: 0.7,
    onChange: setInView,
  });

  return (
    <section
      ref={ref}
      className="relative mx-auto min-h-[30rem] sm:min-h-[30rem] md:min-h-[40rem] lg:min-h-[55rem] lg:max-w-[140rem] lg:overflow-hidden"
    >
      <Image
        alt="Hero Banner"
        src="/assets/images/hero_image_small.avif"
        width="1920"
        height="1080"
        className="absolute inset-0 z-0 h-full w-full object-cover object-center"
      />

      <div className="hero-banner__filter-left absolute left-0 top-0 hidden h-full w-1/12 xl:block"></div>
      <div className="hero-banner__filter-right absolute  right-0 top-0 hidden h-full w-1/12 xl:block"></div>

      <div className="hero-banner__filter2 absolute left-0 top-0 h-full w-5/6 xl:w-4/6"></div>
      <div className="hero-banner__filter1 absolute  bottom-0 h-hero-filter-1 w-full md:min-h-[40rem]"></div>

      <div className="absolute left-[5%] top-[22%] z-10 sm:left-[10%] sm:top-[25%] md:left-[10%] md:top-[14%]">
        <div className="hero-banner__headline">
          <p>NIPE</p>
          <p>SOLUTIONS</p>
        </div>
        <div className="hero-banner__location px-4 font-aller">
          <p>VIENNA</p>
        </div>
        <div className="my-6">
          <p className="hero-banner__subtitle pr-20 font-aller">
            <Balancer>
              Passionate Software Developer: Innovating, Learning, and Growing
              to Elevate Your Business
            </Balancer>
          </p>
        </div>
        <Link
          role="button"
          href="#contact"
          className="hero-banner__button transition-all hover:border-b-2 hover:border-secondary-500 focus:border-b-2 focus:border-secondary-400 focus:outline-2 focus:outline-secondary-400 "
        >
          CONTACT ME
        </Link>
      </div>
    </section>
  );
};

export { HeroBanner };
