import { AnimatePresence, motion } from 'framer-motion';

import { AfterContact } from './AfterContact';
import { ContactDetails } from './ContactDetails';
import { ContactForm } from './ContactForm';
import { useContactStore } from './ContactStore';

export const ContactSection = () => {
  const contactState = useContactStore((state) => state.state);

  return (
    <section
      id="contact"
      className="mx-auto mt-10 flex max-w-4xl flex-col flex-wrap justify-between overflow-hidden rounded-xl bg-primary-600/80 p-8 text-secondary-500 sm:flex-row sm:shadow-lg md:items-stretch"
    >
      <ContactDetails />

      <span className="hidden w-0 border-l border-secondary-500/70 md:block"></span>
      <span className="h-0 w-full border-t border-secondary-500/70 md:hidden"></span>

      <AnimatePresence>
        {contactState === 'success' ? (
          <motion.div
            key="after-contact-form-motion"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <AfterContact />
          </motion.div>
        ) : (
          <motion.div
            key="contact-form-motion"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ContactForm />
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};
