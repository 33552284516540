import { About } from '@/components/about';
import { ContactSection } from '@/components/contactsection';
import { HeroBanner } from '@/components/herobanner';
import { Services } from '@/components/services';
import { Timeline } from '@/components/timeline';
import { Meta } from '@/layouts/Meta';
import { Layout } from '@/templates/layout';

const HomePage = () => {
  return (
    <Layout
      meta={
        <Meta
          title="NIPE Solutions - Software development that fits your needs"
          description="I am your partner when it comes to software development. I offer a wide range of services to help you achieve your goals."
        />
      }
    >
      <HeroBanner />

      <div className="flex flex-col items-center gap-20 md:mt-32">
        <About />
        <Timeline />
        <Services />
        <ContactSection />
      </div>
    </Layout>
  );
};

export default HomePage;
