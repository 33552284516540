import Image from 'next/image';
import { Fragment, useEffect, useState } from 'react';
import Balancer from 'react-wrap-balancer';
import Typewriter from 'typewriter-effect';

import { Headline } from '../headline';

const people = [
  {
    name: 'Nicholas Petrasek',
    roles: [
      'CEO / Founder',
      'Software Engineer',
      'Software Architect',
      'Full-Stack Developer',
    ],
    imageUrl: 'assets/images/people/nicholas/profile_picture_2.avif',
    githubUrl: 'https://github.com/Cylop',
    linkedinUrl: 'https://www.linkedin.com/in/npetrasek/',
  },
];

export const About = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <section className="relative text-secondary-500">
      <a id="about" className="relative -top-16" />
      <div className="my-10 mr-auto w-full py-4 pt-10 text-lg text-secondary-500 sm:px-24 sm:pt-0 md:px-32 lg:ml-auto ">
        <div className="mx-auto max-w-4xl px-6 lg:px-8">
          <Headline as="h2" className="flex w-full justify-center md:flex-none">
            About me
          </Headline>
          <ul
            role="list"
            className="mt-4 grid grid-cols-1 gap-4 md:gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-6 lg:gap-4"
          >
            {people.map((person) => (
              <Fragment key={person.name}>
                <li className="flex w-full flex-col items-center justify-center overflow-hidden rounded-2xl bg-gray-800 px-8 py-10 shadow-lg shadow-black/40 lg:col-span-2">
                  <div className="h-40 w-40 overflow-hidden rounded-full border border-black shadow-[0px_0px_0px_3px_#940a5b] md:h-56 md:w-56 lg:h-48 lg:w-48">
                    <Image
                      className="mx-auto rotate-6 rounded-full object-cover"
                      width="350"
                      height="350"
                      src={person.imageUrl}
                      alt="Nicholas Petrasek profile picture"
                    />
                  </div>

                  <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight">
                    {person.name}
                  </h3>
                  <p className="text-sm leading-6 text-gray-400">
                    {isMounted && (
                      <Typewriter
                        options={{
                          strings: person.roles,
                          autoStart: true,
                          loop: true,
                        }}
                      />
                    )}
                  </p>
                  <ul role="list" className="mt-6 flex justify-center gap-x-6">
                    <li>
                      <a
                        href={person.githubUrl}
                        target="_blank"
                        className="text-gray-400 hover:text-gray-300 focus:text-accent-500 focus:outline-none"
                        rel="noreferrer"
                      >
                        <span className="sr-only">Github</span>
                        <svg
                          className="h-5 w-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        href={person.linkedinUrl}
                        target="_blank"
                        className="text-gray-400 hover:text-gray-300 focus:text-accent-500 focus:outline-none"
                        rel="noreferrer"
                      >
                        <span className="sr-only">LinkedIn</span>
                        <svg
                          className="h-5 w-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="mx-auto h-full w-full overflow-hidden rounded-2xl bg-gray-800 px-8 py-6 shadow-lg shadow-black/40 md:py-10 lg:col-span-4 lg:mx-0">
                  <div className="text-justify font-normal">
                    {isMounted && (
                      <Balancer>
                        My professional journey began in 2011 with Java, leading
                        me to develop plugins for Minecraft and build game
                        servers hosting 400+ concurrent users. This sparked my
                        love for technology and innovation. <br /> <br />
                        I later expanded into hardware, apprenticing as an IT
                        Technician, but programming remained my passion. From 2D
                        platformers to business applications, I&apos;ve always
                        been eager to learn and push the boundaries. <br />
                        <br /> A significant achievement is my standard for
                        tracking and maintaining high-quality, reusable code,
                        free of any &apos;code smell&apos;.
                      </Balancer>
                    )}
                  </div>
                </li>
              </Fragment>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
