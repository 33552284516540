import { type FunctionComponent } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import { useBreakpoint } from '@/hooks/tailwind';

import { Headline } from '../headline';
import { SyntaxStyle } from './syntaxhighlight';
import { timeentries } from './timeentries';

interface TimelineProps {}

const Timeline: FunctionComponent<TimelineProps> = () => {
  const isDesktop = useBreakpoint('md');
  return (
    <section className="container max-w-4xl font-aller md:px-5 lg:max-w-screen-2xl">
      <a id="career" className="relative -top-16" />
      <Headline as="h2" className="flex w-full justify-center md:flex-none">
        Career Progression
      </Headline>
      <div className="my-6 w-full font-aller shadow-lg shadow-black/40">
        <div className="mt-4 bg-primary-600 md:rounded-md ">
          <div className="flex justify-between rounded-t-md border-b border-gray-900 bg-primary-700/80 px-2 py-1">
            <span className="flex items-center gap-2 justify-self-start">
              <span className="relative inline-flex h-3 w-3 rounded-full bg-red-500"></span>
              <span className="relative inline-flex h-3 w-3 rounded-full bg-yellow-500"></span>
              <span className="relative inline-flex h-3 w-3 rounded-full bg-green-500"></span>
            </span>
            <span className="justify-self-center">Terminal</span>
            <span className="w-14"></span>
          </div>
          <div className="flex flex-col gap-3 px-4 pb-2 pt-4 ">
            <div>
              <pre className="overflow-auto whitespace-normal">
                &gt; curl -XGET{' '}
                <span className="hidden lg:inline">
                  -H &quot;Content-type: application/json&quot;
                </span>
                &apos;https://nipesolutions.com/api/timeline&apos;
              </pre>
              <div className="mt-2">Status: 200 OK, Body: </div>
            </div>
            <hr />
            <div className="max-h-[45rem] overflow-x-auto lg:text-lg">
              <SyntaxHighlighter
                language="json"
                style={SyntaxStyle}
                className="rounded-md"
              >
                {JSON.stringify(timeentries, null, isDesktop ? 4 : 2)}
              </SyntaxHighlighter>
              {/* <div className="rounded-md bg-primary-500">
                <JsonView src={timeentries} />
              </div> */}
            </div>

            <span>
              &gt; <span className="blink text-lg">|</span>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Timeline };
