import type { FunctionComponent } from 'react';
import { HiMail } from 'react-icons/hi';

import { Headline } from '../headline';

interface ContactDetailsProps {}

export const ContactDetails: FunctionComponent<ContactDetailsProps> = () => {
  return (
    <div className="flex-1 rounded p-8">
      <Headline className="mb-6">Ready to chat?</Headline>
      <p className="mb-6 text-lg">
        Let&apos;s collaborate, share ideas, or just have a friendly
        conversation. Fill in the details, or send me an email, and I&apos;ll
        get back to you!
      </p>
      <div className="text-lg">
        <div className="mb-4 flex items-center">
          <a
            href="mailto:office@nipesolutions.com"
            className="flex items-center text-secondary-500 hover:underline"
          >
            <span className="mr-2 text-accent-500">
              <HiMail className="h-5 w-5" />
            </span>

            <span className="ml-1">office@nipesolutions.com</span>
          </a>
        </div>
        {/* <div className="flex items-center">
      <span className="mr-2 text-accent-500">
        <HiPhone />
      </span>
      <span>+123 456 7890</span>
</div> */}
      </div>
    </div>
  );
};
